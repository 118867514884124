import React, { Component } from 'react';
import anime from 'animejs';
import styled from 'styled-components';

const MountainsContainer = styled.div`
  visibility: ${props => (props.loaded === 'complete' ? 'visible' : 'hidden')};
  position: absolute;
  width: 25%;
  bottom: 0;
`;

const MountainsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SVG = styled.svg`
  flex: 1;
  overflow: visible!important;
`;

class Mountains extends Component {
  componentDidMount() {
    this.onLoadSVG();
  }
  onHover(value) {
    anime({
        targets: document.getElementById(value),
        scale: [1.2, 1],
        delay: function (el, i) { return 200 + (i * 300); },
    });
  }
  onLoadSVG(val) {
    anime({
      targets: ['#one', '#two', '#three', '#four'],
      scale: [1.3, 1],
      delay: function(el) {
        if (el.id === 'one') {
          return 200;
        } else if (el.id === 'two' || el.id === 'four') {
          return 300;
        } else {
          return 500;
        }
      }
  });
  }
  render() {
    return(
      <MountainsContainer {...this.props} id="mountains">
        <MountainsWrapper>
            <SVG onMouseOver={this.onHover.bind(this, 'one')} id="one" viewBox="100 0 541 493" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Landing" transform="translate(-300.000000, -368.000000)" fill="#99FF99">
                        <path d="M670.396563,368 C759.817267,368 802.680838,426.684997 880.924552,587.704343 C933.087028,695.050573 986.376553,786.100851 1040.79313,860.855176 L300,860.855176 C357.458196,749.779822 407.115504,649.73179 448.971923,560.711082 C511.756552,427.180019 582.269093,368 670.396563,368 Z" id="Triangle-Copy-7"></path>
                    </g>
                </g>
            </SVG>
            <SVG onMouseOver={this.onHover.bind(this, 'two')} id="two" viewBox="0 0 770 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Landing" transform="translate(-351.000000, -568.000000)" fill="#658DFE">
                        <path d="M736.022969,568 C828.974763,568 873.530952,629.00238 954.864393,796.380138 C1009.08669,907.965311 1064.48054,1002.61103 1121.04594,1080.31729 L351,1080.31729 C410.727134,964.855736 462.345335,860.856957 505.854602,768.320954 C571.118502,629.516949 644.415477,568 736.022969,568 Z" id="Triangle-Copy-11"></path>
                    </g>
                </g>
            </SVG>
            <SVG onMouseOver={this.onHover.bind(this, 'three')} id="three" viewBox="50 0 800 359" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Landing" transform="translate(-764.000000, -721.000000)" fill="#FF6167">
                        <path d="M1271.9574,721 C1424.79738,721 1430.44506,819.380872 1537.74755,936.682954 C1609.28255,1014.88434 1690.00496,1062.67117 1779.9148,1080.04343 L764,1080.04343 C863.687707,1049.32108 942.232256,1001.53426 999.633647,936.682954 C1085.73573,839.405999 1119.11742,721 1271.9574,721 Z" id="Triangle-Copy-10"></path>
                    </g>
                </g>
            </SVG>
            <SVG onMouseOver={this.onHover.bind(this, 'four')} id="four" viewBox="0 0 734 489" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Landing" transform="translate(-960.000000, -150.000000)" fill="#FCD859">
                        <path d="M1326.90439,150 C1415.48201,150 1457.94146,208.131703 1535.44747,367.632924 C1587.11815,473.967071 1639.90525,564.158907 1693.80877,638.208433 L960,638.208433 C1016.91647,528.180321 1066.1056,429.075563 1107.56738,340.894161 C1169.76007,208.622057 1239.6078,150 1326.90439,150 Z" id="Triangle-Copy-12"></path>
                    </g>
                </g>
            </SVG>
        </MountainsWrapper>
      </MountainsContainer>
    )
  }
}

export { Mountains };
