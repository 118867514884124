/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Mountains } from './common/Mountains';
import "./layout.css"
import '../assets/fonts/fengardo/style.css';
import styled from 'styled-components';

const MainContainer = styled.div`
  min-height: 100vh;
  padding-bottom: ${props => props.paddingTop}px;
`;

class Layout extends React.Component {
  state = {
    padding: 0,
    loaded: false
  };
  componentDidMount() {
    window.addEventListener("resize", this.updatePadding);
    this.updatePadding();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePadding);
  }

  updatePadding = () => {
    const mountains = document.getElementById('mountains');
    this.setState({ loaded: 'complete', padding: mountains.clientHeight });
  }

  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <MainContainer paddingTop={this.state.padding}>  
              <Header siteTitle={data.site.siteMetadata.title} />
              <div
                style={{
                  margin: `0 auto`,
                  maxWidth: 960,
                  padding: `0px 1.0875rem 1.45rem`,
                  paddingTop: 0,
                  marginBottom: '40px'
                }}
              >
                <main>{children}</main>
              </div>
              <Mountains loaded={this.state.loaded} />
            </MainContainer>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
