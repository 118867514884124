import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../utils';
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const AnimatedLink = styled(AniLink)`
  font-size: ${props => !props.fontSize ? '14' : props.fontSize}px;
  color: ${props => getColor(props.fontcolor)};
  font-family: 'FengardoNeueRegular';
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid ${props => (props.underlineColor ? getColor(props.underlineColor) : getColor('black'))};
  }
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;

const ExternalLink = styled.a`
  font-size: ${props => !props.fontSize ? '14' : props.fontSize}px;
  color: ${props => getColor(props.fontcolor)};
  font-family: 'FengardoNeueRegular';
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid ${props => (props.underlineColor ? getColor(props.underlineColor) : getColor('black'))};
  }
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;

const Linking = styled(Link)`
  font-size: ${props => !props.fontSize ? '14' : props.fontSize}px;
  color: ${props => getColor(props.fontcolor)};
  font-family: 'FengardoNeueRegular';
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${getColor('black')};
  }
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;


const H1 = styled.h1`
  font-size: ${props => !props.fontSize ? '42' : props.fontSize}px;
  font-family: 'FengardoNeueRegular';
  font-weight: 500;
  color: ${props => getColor(props.color)};
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;

const H2 = styled.h2`
  color: ${props => getColor(props.color)};
  font-size: ${props => !props.fontSize ? '30' : props.fontSize}px;
  font-family: 'FengardoNeueRegular';
  font-weight: 500;
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;

const H3 = styled.h3`
  font-size: ${props => !props.fontSize ? '20' : props.fontSize}px;
  font-family: 'FengardoNeueRegular';
  font-weight: 500;
  color: ${props => getColor(props.color)};
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
`;

const WrapperDiv = styled.div`
  font-size: ${props => !props.fontSize ? '16px' : props.fontSize}px;
  font-family: 'FengardoNeueRegular';
  font-weight: 500;
  color: ${props => getColor(props.color)};
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
  padding-bottom: 30px;
`;

const Paragraph = styled.p`
  font-size: ${props => !props.fontSize ? '16px' : props.fontSize}px;
  font-family: 'FengardoNeueRegular';
  font-weight: 500;
  color: ${props => getColor(props.color)};
  text-transform: ${props => (props.transform ? props.transform : 'initial')};
  padding: 0;
  margin: 0;
`;

const Typography = props => {
  if (props.variant === 'h1' ) return <H1 {...props}>{props.children}</H1>;
  if (props.variant === 'h2' ) return <H2 {...props}>{props.children}</H2>;
  if (props.variant === 'h3' ) return <H3 {...props}>{props.children}</H3>;

  return <Paragraph {...props}>{props.children}</Paragraph>;
};

export { AnimatedLink, ExternalLink, Typography, Linking, WrapperDiv };