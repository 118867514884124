import PropTypes from "prop-types"
import React from "react"
import { AnimatedLink, ExternalLink } from './common';
import styled from 'styled-components';
import { randomColor } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core'
import {
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  max-width: 960px;
  margin: 30px auto;
  padding: 40px 1.0875rem;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const HeaderItem = styled.div`
  font-family: 'FengardoNeueRegular';
  display: flex;
  flex: 1;
  justify-content: ${props => (props.right ? 'flex-end' : 'flex-start')};
  @media (max-width: 500px) {
    font-size: 16px;
    padding-bottom: 30px;
  }
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: black;
`;

const SocialLink = styled(ExternalLink)`
  padding: 0 10px;
  svg {
    width: 22px;
  }
  &:hover {
    border: none;
  }
`;

const MenuLink = styled(AnimatedLink)`
  padding: 0;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

const SocialWrapper = styled.div`
`;

const TitleLink = styled(AnimatedLink)`
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

const MenuDiv = styled.div`
  padding: ${props => props.nopadding ? 0 : '0 20px'};
`;

const links = [
  {
    path: '/about',
    name: 'About',
    visible: true
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    visible: false
  }
]

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <HeaderItem>
      <MenuDiv nopadding>  
        <TitleLink paintDrip fontSize="20" hex={randomColor()} fontcolor="red" to="/">
          Pedro Porto
        </TitleLink>
      </MenuDiv>
    </HeaderItem>
    <HeaderItem right>
      {links.map((link, index) => {
        if(link.visible)
        return (
          <MenuDiv key={index}>
            <MenuLink paintDrip hex={randomColor()} transform="uppercase" to={link.path}>{link.name}</MenuLink>
          </MenuDiv>
        )

        return null;
      }
    )}
    </HeaderItem>
    <SocialWrapper>
      <SocialLink fontSize="18" href="https://github.com/pedroporto" target="_blank" rel="noopener" aria-label="Github">
        <SocialIcon icon={faGithub} />
      </SocialLink>
      <SocialLink fontSize="18" href="https://twitter.com/pedromc_porto" target="_blank" rel="noopener" aria-label="Twitter">
        <SocialIcon icon={faTwitter} />
      </SocialLink>
    </SocialWrapper>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
